import React from 'react'
import { IconProps } from '../../../common/types'

const QbsSortIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.85874 9.0031C4.98351 8.87837 5.1527 8.80831 5.32913 8.80831C5.50555 8.80831 5.67475 8.87837 5.79951 9.0031L7.99044 11.194L10.1814 9.0031C10.2427 8.93956 10.3162 8.88887 10.3973 8.854C10.4785 8.81913 10.5658 8.80078 10.6541 8.80001C10.7425 8.79925 10.8301 8.81608 10.9119 8.84953C10.9936 8.88299 11.0679 8.93239 11.1304 8.99486C11.1929 9.05733 11.2423 9.13161 11.2757 9.21338C11.3092 9.29514 11.326 9.38276 11.3252 9.4711C11.3245 9.55944 11.3061 9.64674 11.2712 9.72792C11.2364 9.80909 11.1857 9.8825 11.1221 9.94388L8.46082 12.6052C8.33606 12.7299 8.16686 12.8 7.99044 12.8C7.81402 12.8 7.64482 12.7299 7.52005 12.6052L4.85874 9.94388C4.73401 9.81911 4.66394 9.64991 4.66394 9.47349C4.66394 9.29707 4.73401 9.12787 4.85874 9.0031Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1305 6.99671C11.0057 7.12144 10.8365 7.19151 10.6601 7.19151C10.4836 7.19151 10.3144 7.12144 10.1897 6.99671L7.99876 4.80579L5.80783 6.99671C5.74646 7.06026 5.67304 7.11094 5.59187 7.14581C5.5107 7.18068 5.42339 7.19904 5.33505 7.1998C5.24671 7.20057 5.1591 7.18374 5.07733 7.15028C4.99557 7.11683 4.92128 7.06743 4.85881 7.00496C4.79634 6.94249 4.74694 6.8682 4.71349 6.78644C4.68003 6.70467 4.6632 6.61706 4.66397 6.52872C4.66473 6.44038 4.68309 6.35307 4.71796 6.2719C4.75283 6.19073 4.80351 6.11731 4.86706 6.05594L7.52837 3.39463C7.65314 3.2699 7.82233 3.19983 7.99876 3.19983C8.17518 3.19983 8.34437 3.2699 8.46914 3.39463L11.1305 6.05594C11.2552 6.18071 11.3253 6.3499 11.3253 6.52633C11.3253 6.70275 11.2552 6.87194 11.1305 6.99671Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default QbsSortIcon
