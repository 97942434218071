import React from 'react'
import { IconProps } from '../../../common/types'

const SaveFile: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.975 4.3127L18.3375 0.975195L18.2625 0.937695C18 0.750195 17.7 0.637695 17.4375 0.637695H4.4625C2.8875 0.637695 1.6125 1.9127 1.6125 3.4877V20.4377C1.6125 22.0127 2.8875 23.2877 4.4625 23.2877H19.5375C21.1125 23.2877 22.3875 22.0127 22.3875 20.4377V5.2877C22.3875 4.9502 22.275 4.6127 21.975 4.3127ZM14.9625 2.3627V4.5002H7.125V2.3627H14.9625ZM8.0625 21.6752V14.7377H15.9375V21.6377H8.0625V21.6752ZM20.7 20.4752C20.7 21.1127 20.175 21.6377 19.5375 21.6377H17.625V14.4002C17.625 13.6877 17.025 13.0877 16.3125 13.0877H7.6875C6.975 13.0877 6.375 13.6877 6.375 14.4002V21.6752H4.4625C3.825 21.6752 3.3 21.1502 3.3 20.5127V3.5252C3.3 2.8877 3.825 2.3627 4.4625 2.3627H5.4375V4.8752C5.4375 5.5877 6.0375 6.1877 6.75 6.1877H15.3375C16.05 6.1877 16.65 5.5877 16.65 4.8752V2.3627H17.325L20.7 5.4752V20.4752Z"
        fill="currentColor"
      />
      <path
        d="M13.5375 15.9375H10.4625C10.0125 15.9375 9.60001 16.3125 9.60001 16.8C9.60001 17.2875 9.97501 17.6625 10.4625 17.6625H13.5375C13.9875 17.6625 14.4 17.2875 14.4 16.8C14.4 16.3125 13.9875 15.9375 13.5375 15.9375Z"
        fill="currentColor"
      />
      <path
        d="M13.5375 18.7876H10.4625C10.0125 18.7876 9.60001 19.1626 9.60001 19.6501C9.60001 20.1376 9.97501 20.5126 10.4625 20.5126H13.5375C13.9875 20.5126 14.4 20.1376 14.4 19.6501C14.4 19.1626 13.9875 18.7876 13.5375 18.7876Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SaveFile
