import React from 'react'
import { IconProps } from '../../../common/types'

const AccountingIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00085 5V4.2C7.00085 2.43 7.00085 1 10.2009 1H11.8009C15.0009 1 15.0009 2.43 15.0009 4.2V5M13.0009 13.02C13.0009 13.01 13.0009 13.01 13.0009 13V12C13.0009 11 13.0009 11 12.0009 11H10.0009C9.00085 11 9.00085 11 9.00085 12V13.03M13.0009 13.02C13.0009 14.11 12.9909 15 11.0009 15C9.02085 15 9.00085 14.12 9.00085 13.03M13.0009 13.02C15.7009 12.68 18.3409 11.68 20.6509 10M9.00085 13.03C6.41085 12.74 3.87085 11.81 1.62085 10.27M15.0008 21C19.0208 21 19.7408 19.39 19.9508 17.43L20.7008 9.43C20.9708 6.99 20.2708 5 16.0008 5H6.0008C1.7308 5 1.0308 6.99 1.3008 9.43L2.0508 17.43C2.2608 19.39 2.9808 21 7.0008 21H15.0008Z"
        stroke=""
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AccountingIcon
