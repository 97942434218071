import React from 'react'
import { IconProps } from '../../../common/types'

const EditIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.29623 1.66148H5.99895C2.75577 1.66148 1.4585 2.94905 1.4585 6.16797V10.0307C1.4585 13.2496 2.75577 14.5372 5.99895 14.5372H9.89078C13.134 14.5372 14.4312 13.2496 14.4312 10.0307V8.74311"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.45407 7.39113C5.25948 7.58426 5.06488 7.9641 5.02597 8.24093L4.74705 10.1787C4.64327 10.8804 5.14272 11.3697 5.84974 11.2732L7.80213 10.9963C8.07456 10.9577 8.45726 10.7646 8.65833 10.5714L13.7696 5.4984C14.6517 4.62286 15.0669 3.60568 13.7696 2.31811C12.4723 1.03054 11.4475 1.44256 10.5653 2.31811L5.45407 7.39113Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83228 3.04551C10.2669 4.58415 11.4798 5.78803 13.0365 6.22581"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditIcon
