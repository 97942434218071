import CryptoJS from 'crypto-js'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type domains = 'assessor-portal' | 'admin-portal' | 'org-portal'

const SECRET_KEY = 'DOMAIN_STORE' // Replace with your actual secret key

const encrypt = (data: any) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()
}

const decrypt = (data: string) => {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY)
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}

export const domainTypes = {
  EMPLOYEE: 'Employee',
  ASSESSOR: 'Assessor',
  ORGANISATION: 'Organisation',
}
export type DomainTypes = 'Employee' | 'Assessor' | 'Organisation'

export const domainTypeMappings: Record<domains, string> = {
  'assessor-portal': 'Assessor',
  'org-portal': 'Organisation',
  'admin-portal': 'Employee',
}

export const getDomainType = (domain: string): string => {
  const hasKey = (Object.keys(domainTypeMappings) as domains[]).some((key) =>
    domain.includes(key)
  )
  const portal = domainTypeMappings[domain as keyof typeof domainTypeMappings]
  return hasKey ? portal : 'Employee'
}

//never commit this file
export const useDomainManageStore = create<ManageStore>()(
  persist(
    (set) => ({
      domainType: process.env.REACT_APP_PORTAL_DOMAIN
        ? getDomainType(process.env.REACT_APP_PORTAL_DOMAIN)
        : 'Employee',

      setDomainType: (data) => set(() => ({ domainType: data })),
    }),
    {
      name: 'd_@@$_man#$@#_s_@_#_t__#_re',
      serialize: (state) => encrypt(state),
      deserialize: (str) => decrypt(str),
    }
  )
)

type ManageStore = {
  domainType: string
  setDomainType: (data: any) => void
}
