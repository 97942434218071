import React from 'react'
import { IconProps } from '../../../common/types'

const ActivateIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_265_24946)">
        <path
          d="M5.80944 8L7.26985 9.46041L10.1907 6.5396M14.5719 8C14.5719 11.6295 11.6296 14.5718 8.00005 14.5718C4.37053 14.5718 1.42822 11.6295 1.42822 8C1.42822 4.37048 4.37053 1.42818 8.00005 1.42818C11.6296 1.42818 14.5719 4.37048 14.5719 8Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_265_24946">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ActivateIcon
