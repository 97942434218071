import React from 'react'
import { IconProps } from '../../../common/types'

const AttachmentIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1142 4.66667L5.7237 9.05719C5.203 9.57789 5.203 10.4221 5.7237 10.9428C6.24439 11.4635 7.08861 11.4635 7.60931 10.9428L11.8855 6.55228C12.9269 5.51089 12.9269 3.82245 11.8855 2.78105C10.8441 1.73965 9.15562 1.73965 8.11422 2.78105L3.83808 7.17157C2.27598 8.73367 2.27598 11.2663 3.83808 12.8284C5.40017 14.3905 7.93283 14.3905 9.49493 12.8284L13.6665 8.66667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AttachmentIcon
