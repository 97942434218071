import React from 'react'
import { IconProps } from '../../../common/types'

const LogoutIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 10.6667L14 8.00008M14 8.00008L11.3333 5.33341M14 8.00008L4.66667 8.00008M8.66667 10.6667V11.3334C8.66667 12.438 7.77124 13.3334 6.66667 13.3334H4C2.89543 13.3334 2 12.438 2 11.3334V4.66675C2 3.56218 2.89543 2.66675 4 2.66675H6.66667C7.77124 2.66675 8.66667 3.56218 8.66667 4.66675V5.33341"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LogoutIcon
