import React from 'react'
import { IconProps } from '../../../common/types'

const DashboardIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1432 7.37374C1.3632 7.99374 0.863195 9.30377 1.03319 10.2838L2.3632 18.2437C2.6032 19.6637 3.96319 20.8137 5.40319 20.8137H16.6032C18.0332 20.8137 19.4032 19.6537 19.6432 18.2437L20.9732 10.2838C21.1332 9.30377 20.6332 7.99374 19.8632 7.37374L12.9332 1.83376C11.8632 0.97376 10.1332 0.973751 9.0732 1.82375L2.1432 7.37374Z"
        stroke=""
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0032 14.5037C9.62246 14.5037 8.50317 13.3844 8.50317 12.0037C8.50317 10.623 9.62246 9.50366 11.0032 9.50366C12.3839 9.50366 13.5032 10.623 13.5032 12.0037C13.5032 13.3844 12.3839 14.5037 11.0032 14.5037Z"
        stroke=""
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DashboardIcon
