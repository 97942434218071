import React from 'react'
import { IconProps } from '../../../common/types'

const DescendingIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8334 5.5L8.00008 11.3333L2.16675 5.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DescendingIcon
