import { z } from 'zod'

const MAX_FILE_SIZE = 5000000
export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  // 'application/vnd.ms-excel', // .xls
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]
export const ACCEPTED_IMAGE_TYPES_FOR_LOGO = [
  'image/jpeg',
  'image/jpg',
  'image/png',
]
export const fileSchema = z.object({
  file: z
    .union([z.instanceof(File), z.any()])
    .refine((file) => !(file instanceof File) || file.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine(
      (file) =>
        !(file instanceof File) || ACCEPTED_IMAGE_TYPES.includes(file.type),
      {
        message: 'File type not allowed',
      }
    ),
})
export const logoSchema = z.object({
  file: z
    .union([z.instanceof(File), z.any()])
    .refine((file) => !(file instanceof File) || file.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine(
      (file) =>
        !(file instanceof File) ||
        ACCEPTED_IMAGE_TYPES_FOR_LOGO.includes(file.type),
      {
        message: 'Only JPEG, JPG, and PNG image formats are accepted',
      }
    ),
})
export type FileSchema = z.infer<typeof fileSchema>
export type LogoSchema = z.infer<typeof logoSchema>
