import React from 'react'
import { IconProps } from '../../../common/types'

const VerifyIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_265_25250)">
        <path
          d="M12.6614 3.31634C11.4685 2.12341 9.82045 1.38556 8.00007 1.38556C4.35937 1.38556 1.40796 4.33694 1.40796 7.97767C1.40796 11.6184 4.35937 14.5698 8.00007 14.5698C11.6408 14.5698 14.5922 11.6184 14.5922 7.97767"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6174 2.41177V3.99104L12.0381 4.27012"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3669 11.088H5.63281V7.406H10.3669V11.088Z"
          stroke="currentColor"
          strokeWidth="1.15998"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00004 4.24998C7.05589 4.24998 6.29053 5.01534 6.29053 5.95949V7.40601H9.70956V5.95949C9.70956 5.01534 8.94417 4.24998 8.00004 4.24998Z"
          stroke="currentColor"
          strokeWidth="1.15998"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 9.00088L8 9.49319"
          stroke="currentColor"
          strokeWidth="1.15998"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_265_25250">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VerifyIcon
