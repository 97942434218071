import React from 'react'
import { IconProps } from '../../../common/types'

const ExclamationDanger: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.16797V8.66797"
        stroke="#C90909"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0548 10.2787C13.0548 11.0254 12.6548 11.7187 12.0081 12.0987L8.0481 14.3854C7.40143 14.7587 6.60141 14.7587 5.94808 14.3854L1.98808 12.0987C1.34141 11.7254 0.941406 11.032 0.941406 10.2787V5.71872C0.941406 4.97205 1.34141 4.27869 1.98808 3.89869L5.94808 1.61203C6.59475 1.2387 7.39476 1.2387 8.0481 1.61203L12.0081 3.89869C12.6548 4.27869 13.0548 4.96539 13.0548 5.71872V10.2787Z"
        stroke="#C90909"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.8008V10.8674"
        stroke="#C90909"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ExclamationDanger
