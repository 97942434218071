import React from 'react'
import { IconProps } from '../../../common/types'

const DiscountIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 2C20 2 22 4 22 9V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H15Z"
        stroke=""
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57031 15.2704L15.1103 8.73047"
        stroke=""
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.98001 10.3701C8.3007 10.3701 7.75 9.81948 7.75 9.14017C7.75 8.46086 8.3007 7.91016 8.98001 7.91016C9.65932 7.91016 10.21 8.46086 10.21 9.14017C10.21 9.81948 9.65932 10.3701 8.98001 10.3701Z"
        stroke=""
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.519 16.0889C14.8397 16.0889 14.2891 15.5382 14.2891 14.8589C14.2891 14.1796 14.8397 13.6289 15.519 13.6289C16.1984 13.6289 16.7491 14.1796 16.7491 14.8589C16.7491 15.5382 16.1984 16.0889 15.519 16.0889Z"
        stroke=""
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DiscountIcon
