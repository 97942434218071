import React from 'react'
import { IconProps } from '../../../common/types'

const BreadcrumbsArrow: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83588 11.7657C5.52346 11.4533 5.52346 10.9467 5.83588 10.6343L8.47019 8L5.83588 5.36569C5.52346 5.05327 5.52346 4.54673 5.83588 4.23431C6.1483 3.9219 6.65483 3.9219 6.96725 4.23431L10.1672 7.43431C10.4797 7.74673 10.4797 8.25327 10.1672 8.56569L6.96725 11.7657C6.65483 12.0781 6.1483 12.0781 5.83588 11.7657Z"
        fill="#585858"
      />
    </svg>
  )
}

export default BreadcrumbsArrow
