import React from 'react'
import { IconProps } from '../../../common/types'

const PaperClip: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6431 5.83333L7.15499 11.3215C6.50411 11.9724 6.50411 13.0276 7.15499 13.6785C7.80586 14.3294 8.86113 14.3294 9.51201 13.6785L14.8572 8.19036C16.1589 6.88861 16.1589 4.77806 14.8572 3.47631C13.5554 2.17456 11.4449 2.17456 10.1431 3.47631L4.79796 8.96447C2.84534 10.9171 2.84534 14.0829 4.79796 16.0355C6.75058 17.9882 9.91641 17.9882 11.869 16.0355L17.0835 10.8333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PaperClip
