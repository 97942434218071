import React from 'react'
import { IconProps } from '../../../common/types'

const Notification: React.FC<IconProps> = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0013 10.3333H12.3346L11.398 9.39671C11.144 9.1427 11.0013 8.79819 11.0013 8.43897V6.33334C11.0013 4.59171 9.88823 3.11006 8.33464 2.56094V2.33333C8.33464 1.59695 7.73768 1 7.0013 1C6.26492 1 5.66797 1.59695 5.66797 2.33333V2.56094C4.11438 3.11006 3.0013 4.59171 3.0013 6.33334V8.43897C3.0013 8.79819 2.8586 9.1427 2.60459 9.39671L1.66797 10.3333H5.0013M9.0013 10.3333V11C9.0013 12.1046 8.10587 13 7.0013 13C5.89673 13 5.0013 12.1046 5.0013 11V10.3333M9.0013 10.3333H5.0013"
        stroke="#222222"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Notification
