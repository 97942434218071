import React from 'react'
import { IconProps } from '../../../common/types'

const DeactivateIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_265_24956)">
        <path
          d="M6.5379 9.462L7.9999 8M7.9999 8L9.4619 6.538M7.9999 8L6.5379 6.538M7.9999 8L9.4619 9.462M14.5789 8C14.5789 11.6335 11.6334 14.579 7.9999 14.579C4.36642 14.579 1.4209 11.6335 1.4209 8C1.4209 4.36652 4.36642 1.421 7.9999 1.421C11.6334 1.421 14.5789 4.36652 14.5789 8Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_265_24956">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeactivateIcon
