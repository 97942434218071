import React from 'react'
import { IconProps } from '../../../common/types'

const UserIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.85999 23.9024C7.81155 24.1836 8.93621 24.3133 10.2554 24.3133H16.7434C18.0627 24.3133 19.1873 24.1836 20.1389 23.9024M6.85999 23.9024C7.09789 21.0909 9.98505 18.8742 13.4994 18.8742C17.0138 18.8742 19.901 21.0909 20.1389 23.9024M6.85999 23.9024C3.91875 23.0698 2.68604 20.8314 2.68604 16.7439V10.2559C2.68604 4.84921 4.84872 2.68652 10.2554 2.68652H16.7434C22.1502 2.68652 24.3129 4.84921 24.3129 10.2559V16.7439C24.3129 20.8314 23.0801 23.0698 20.1389 23.9024M13.4995 15.8464C11.3585 15.8464 9.62828 14.1055 9.62828 11.9644C9.62828 9.8234 11.3585 8.09321 13.4995 8.09321C15.6405 8.09321 17.3707 9.8234 17.3707 11.9644C17.3707 14.1055 15.6405 15.8464 13.4995 15.8464Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default UserIcon
