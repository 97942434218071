import { IconProps } from '@mui/material'
import React from 'react'

const UpDownArrow: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.85886 9.0039C4.98363 8.87917 5.15283 8.8091 5.32925 8.8091C5.50567 8.8091 5.67487 8.87917 5.79963 9.0039L7.99056 11.1948L10.1815 9.0039C10.2429 8.94035 10.3163 8.88967 10.3974 8.8548C10.4786 8.81993 10.5659 8.80157 10.6543 8.80081C10.7426 8.80004 10.8302 8.81687 10.912 8.85033C10.9937 8.88378 11.068 8.93318 11.1305 8.99565C11.193 9.05812 11.2424 9.13241 11.2758 9.21417C11.3093 9.29594 11.3261 9.38355 11.3253 9.47189C11.3246 9.56023 11.3062 9.64754 11.2714 9.72871C11.2365 9.80988 11.1858 9.8833 11.1223 9.94467L8.46095 12.606C8.33618 12.7307 8.16698 12.8008 7.99056 12.8008C7.81414 12.8008 7.64494 12.7307 7.52017 12.606L4.85886 9.94467C4.73413 9.8199 4.66406 9.65071 4.66406 9.47428C4.66406 9.29786 4.73413 9.12867 4.85886 9.0039Z"
        fill="#313131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1306 6.9961C11.0058 7.12083 10.8366 7.1909 10.6602 7.1909C10.4838 7.1909 10.3146 7.12083 10.1898 6.9961L7.99888 4.80518L5.80795 6.9961C5.74658 7.05965 5.67316 7.11033 5.59199 7.1452C5.51082 7.18007 5.42351 7.19843 5.33517 7.19919C5.24683 7.19996 5.15922 7.18313 5.07745 7.14967C4.99569 7.11622 4.9214 7.06682 4.85893 7.00435C4.79646 6.94188 4.74706 6.86759 4.71361 6.78583C4.68015 6.70406 4.66332 6.61645 4.66409 6.52811C4.66486 6.43977 4.68321 6.35246 4.71808 6.27129C4.75295 6.19012 4.80363 6.1167 4.86718 6.05533L7.52849 3.39402C7.65326 3.26929 7.82246 3.19922 7.99888 3.19922C8.1753 3.19922 8.3445 3.26929 8.46926 3.39402L11.1306 6.05533C11.2553 6.1801 11.3254 6.34929 11.3254 6.52572C11.3254 6.70214 11.2553 6.87133 11.1306 6.9961Z"
        fill="#313131"
      />
    </svg>
  )
}

export default UpDownArrow
