import { zodResolver } from '@hookform/resolvers/zod'

import { useForm, FormProvider } from 'react-hook-form'

import { tabularDataSchema } from '../organisation/details/datatables/schema'
import { tabularData } from './store'
import { useState } from 'react'
import Tabularform from '../../components/app/tabularform/TabularForm'
import TabularLoader from '../../components/common/tabularLoader/index'

type Col = 'uk' | 'global' | 'ireland'

const columnTitle: Col[] = ['uk', 'global', 'ireland']
const Form = () => {
  const methods = useForm({
    resolver: zodResolver(tabularDataSchema),
    defaultValues: { tabularData },
  })

  const [loaderBool, setLoaderBool] = useState(true)

  setTimeout(() => {
    setLoaderBool(false)
  }, 3000)

  return (
    <FormProvider {...methods}>
      <div>
        {loaderBool ? (
          <TabularLoader />
        ) : (
          <Tabularform
            tabularData={[]}
            groupedColumns={true}
            hideEdit={false}
            columnTitle={columnTitle}
            editable={true}
          />
        )}
      </div>
    </FormProvider>
  )
}
export default Form
