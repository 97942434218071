import React from 'react'
import { IconProps } from '../../../common/types'

const MenuIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.39844 5C3.39844 4.44772 3.84615 4 4.39844 4H16.3984C16.9507 4 17.3984 4.44772 17.3984 5C17.3984 5.55228 16.9507 6 16.3984 6H4.39844C3.84615 6 3.39844 5.55228 3.39844 5Z"
        fill="#585858"
      />
      <path
        d="M3.39844 10C3.39844 9.44772 3.84615 9 4.39844 9H16.3984C16.9507 9 17.3984 9.44772 17.3984 10C17.3984 10.5523 16.9507 11 16.3984 11H4.39844C3.84615 11 3.39844 10.5523 3.39844 10Z"
        fill="#585858"
      />
      <path
        d="M9.39844 15C9.39844 14.4477 9.84615 14 10.3984 14H16.3984C16.9507 14 17.3984 14.4477 17.3984 15C17.3984 15.5523 16.9507 16 16.3984 16H10.3984C9.84615 16 9.39844 15.5523 9.39844 15Z"
        fill="#585858"
      />
    </svg>
  )
}

export default MenuIcon
