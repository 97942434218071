import React from 'react'
import { IconProps } from '../../../common/types'

const SalesIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.67188 13.3298C7.67188 14.6198 8.66188 15.6598 9.89188 15.6598H12.4019C13.4719 15.6598 14.3419 14.7498 14.3419 13.6298C14.3419 12.4098 13.8119 11.9798 13.0219 11.6998L8.99187 10.2998C8.20187 10.0198 7.67188 9.58984 7.67188 8.36984C7.67188 7.24984 8.54187 6.33984 9.61187 6.33984H12.1219C13.3519 6.33984 14.3419 7.37984 14.3419 8.66984M11 5V17M8 21C3 21 1 19 1 14V8C1 3 3 1 8 1H14C19 1 21 3 21 8V14C21 19 19 21 14 21H8Z"
        stroke=""
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SalesIcon
