import React from 'react'
import { IconProps } from '../../../common/types'

const Edit: React.FC<IconProps> = () => {
  return (
    // <svg
    //   width="18"
    //   height="18"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M16.475 5.40783L18.592 7.52483M17.836 3.54283L12.109 9.26983C11.8131 9.56533 11.6113 9.94181 11.529 10.3518L11 12.9998L13.648 12.4698C14.058 12.3878 14.434 12.1868 14.73 11.8908L20.457 6.16383C20.6291 5.99173 20.7656 5.78742 20.8588 5.56256C20.9519 5.33771 20.9998 5.09671 20.9998 4.85333C20.9998 4.60994 20.9519 4.36895 20.8588 4.14409C20.7656 3.91923 20.6291 3.71492 20.457 3.54283C20.2849 3.37073 20.0806 3.23421 19.8557 3.14108C19.6309 3.04794 19.3899 3 19.1465 3C18.9031 3 18.6621 3.04794 18.4373 3.14108C18.2124 3.23421 18.0081 3.37073 17.836 3.54283Z"
    //     stroke="currentColor"
    //     strokeWidth="1"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M19 15V18C19 18.5304 18.7893 19.0391 18.4142 19.4142C18.0391 19.7893 17.5304 20 17 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H9"
    //     stroke="currentColor"
    //     strokeWidth="1.5"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>

    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.11992 2.07684H7.49833C3.44435 2.07684 1.82275 3.6863 1.82275 7.70996V12.5383C1.82275 16.562 3.44435 18.1715 7.49833 18.1715H12.3631C16.4171 18.1715 18.0387 16.562 18.0387 12.5383V10.9289"
        stroke="#959393"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.8171 9.23891C6.57386 9.48033 6.33062 9.95512 6.28197 10.3012L5.93333 12.7234C5.8036 13.6005 6.42791 14.2121 7.31168 14.0914L9.75218 13.7454C10.0927 13.6971 10.5711 13.4557 10.8224 13.2143L17.2115 6.873C18.3142 5.77857 18.8331 4.50709 17.2115 2.89763C15.5899 1.28817 14.3089 1.8032 13.2062 2.89763L6.8171 9.23891Z"
        stroke="#959393"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.29 3.80688C12.8333 5.73019 14.3495 7.23504 16.2954 7.78225"
        stroke="#959393"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Edit
