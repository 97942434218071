export const tabularColumns = [
  {
    title: 'Employee Count : UK',
    id: '1',
    type: 'uk',
    children: [
      { title: 'Total', id: 1 },
      { title: 'Men', id: 2 },
      { title: 'Women', id: 3 },
      { title: 'Non Binary / Others', id: 4 },
    ],
  },
  {
    title: 'Employee Count : Ireland',
    id: '2',
    type: 'ireland',
    children: [
      { title: 'Total', id: 1 },
      { title: 'Men', id: 2 },
      { title: 'Women', id: 3 },
      { title: 'Non Binary / Others', id: 4 },
    ],
  },
  {
    title: 'Employee Count : Global',
    id: '3',
    type: 'global',
    children: [
      { title: 'Total', id: 1 },
      { title: 'Men', id: 2 },
      { title: 'Women', id: 3 },
      { title: 'Non Binary / Others', id: 4 },
    ],
  },
]
const handleReturn = () => {
  return [
    { label: 'UK', id: 1, value: '0', name: 'total' },
    { label: 'UK', id: 1, value: '0', name: 'men' },
    { label: 'UK', id: 1, value: '0', name: 'women' },
    { label: 'UK', id: 1, value: '0', name: 'nonbinary' },
  ]
}

export const tabularData = [
  {
    title: 'Board Members',
    id: '1',
    grouped: false,
    children: [...handleReturn(), ...handleReturn(), ...handleReturn()],
  },

  {
    id: '1',

    title: 'Senior Managers',
    grouped: true,

    children: [
      {
        rowTitle: '25',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '25 - 34',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '35 - 44',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '45 - 54',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '55 - 64',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '65 +',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: 'Total',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
    ],
  },
  {
    id: '1',

    title: 'Senior Managers',
    grouped: true,

    children: [
      {
        rowTitle: '25',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '25 - 34',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '35 - 44',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '45 - 54',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '55 - 64',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '65 +',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: 'Total',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
    ],
  },
  {
    id: '1',

    title: ' Line Managerss',
    grouped: true,

    children: [
      {
        rowTitle: '25',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '25 - 34',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '35 - 44',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '45 - 54',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '55 - 64',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '65 +',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: 'Total',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
    ],
  },
  {
    id: '1',

    title: ' Graduates / Apprentices',
    grouped: true,

    children: [
      {
        rowTitle: '25',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '25 - 34',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '35 - 44',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '45 - 54',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '55 - 64',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '65 +',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: 'Total',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
    ],
  },
  {
    id: '1',

    title: ' Supporting Roles',
    grouped: true,

    children: [
      {
        rowTitle: '25',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '25 - 34',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '35 - 44',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '45 - 54',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '55 - 64',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '65 +',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: 'Total',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
    ],
  },
  {
    id: '1',

    title: 'Operational Roles',
    grouped: true,

    children: [
      {
        rowTitle: '25',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '25 - 34',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '35 - 44',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '45 - 54',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '55 - 64',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: '65 +',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
      {
        rowTitle: 'Total',
        uk: handleReturn(),
        ireland: handleReturn(),
        global: handleReturn(),
      },
    ],
  },
]
