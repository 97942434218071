import React from 'react'

const NotFoundPage: React.FC = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-5xl font-bold text-gray-800">404</h1>
      <p className="text-gray-600 text-xl mt-2">Page Not Found</p>
      <p className="text-gray-500 mt-2">
        {`We can't find the page you're looking for.`}
      </p>
    </div>
  )
}

export default NotFoundPage
