import React from 'react'
import { IconProps } from '../../../common/types'

const PlusIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0013 1.66797V7.0013M7.0013 7.0013V12.3346M7.0013 7.0013H12.3346M7.0013 7.0013L1.66797 7.0013"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlusIcon
