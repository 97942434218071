import React from 'react'
import { IconProps } from '../../../common/types'

const AsceningIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33325 9.99998L7.99992 5.33331L12.6666 9.99998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AsceningIcon
